/**
 * @file av_recorder.ts
 * Provides utility functions to facilitate AV recording in the browser.
 * @author Nitesh Goel
 */
import window from '@@/bits/global'
import MediaRecorderPolyfill from 'audio-recorder-polyfill'

interface MediaDevice {
  id: string
  label: string
}

if (!window.MediaRecorder || !window.MediaRecorder.prototype.pause) window.MediaRecorder = MediaRecorderPolyfill

declare let MediaRecorder: {
  isTypeSupported?: (type?: string) => boolean
}

const supportsRecording = typeof MediaRecorder !== 'undefined'

/**
 * Note: webkit-based browsers (e.g. Safari) only support recording audio/video in mp4 format at the moment.
 * Reference: https://webkit.org/blog/11353/mediarecorder-api/
 * function supportsRecording(mimeType) {
 *     if (!window.MediaRecorder)
 *         return false;
 *     if (!MediaRecorder.isTypeSupported)
 *         return mimeType.startsWith("audio/mp4") || mimeType.startsWith("video/mp4");
 *     return MediaRecorder.isTypeSupported(mimeType);
 * }
 */
const audioMimeTypes = ['audio/webm', 'audio/ogg', 'audio/wav', 'audio/mp4']
const videoMimeTypes = [
  'video/webm;codecs="vp9,opus"',
  'video/webm;codecs="vp9,vorbis"',
  'video/webm;codecs="vp8,opus"',
  'video/webm;codecs="vp8,vorbis"',
  'video/webm;codecs="h264"',
  'video/webm',
  'video/mpeg',
  // For Safari
  // If codecs is specified, the video is still saved successfully but the playback right after recording failed => don't specify anything for now
  // Video/audio type and browser support reference: https://wiki.whatwg.org/wiki/Video_type_parameters#Browser_Support
  'video/mp4',
]

// MediaRecorder.isTypeSupported only on FF >= 47
// https://stackoverflow.com/questions/43996698/mediarecorder-on-firefox-53-0-2-does-not-support-audio-recording
const isTypeSupported =
  supportsRecording && MediaRecorder.isTypeSupported != null
    ? MediaRecorder.isTypeSupported.bind(MediaRecorder)
    : (): boolean => false

const supportedVideoCodec = ((): string => {
  if (!supportsRecording) {
    return ''
  }
  for (const mt of videoMimeTypes) {
    if (isTypeSupported(mt)) {
      return mt
    }
  }
  return ''
})()

const supportedAudioCodec = ((): string => {
  if (!supportsRecording) {
    return ''
  }
  for (const mt of audioMimeTypes) {
    if (isTypeSupported(mt)) {
      return mt
    }
  }
  return ''
})()

// We'll extract the extension from the codec here.
// E.g. if the codec is video/webm;codecs=vp9, we want to extract webm
const [videoMimeTypeString /*, codecString */] = supportedVideoCodec.split(';')
const [, videoExtensionString] = videoMimeTypeString.split('/')
const supportedVideoExtension = videoExtensionString

const supportedAudioExtension = supportedAudioCodec.split('/').pop()

const supportsVideoRecording = !!supportedVideoExtension
const supportsAudioRecording = !!supportedAudioExtension

const supportsScreenCapture = navigator?.mediaDevices?.getDisplayMedia != null

export {
  supportedAudioCodec,
  supportedAudioExtension,
  supportedVideoCodec,
  supportedVideoExtension,
  supportsAudioRecording,
  supportsRecording,
  supportsScreenCapture,
  supportsVideoRecording,
}
export type { MediaDevice }
